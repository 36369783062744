<template>
    <v-row no-gutters justify="center" align="center" class="mx-5">
        <v-col cols="12" md="6" align="center">
            <v-card class="rounded-lg px-3">
                <v-row align="center" justify="center">
                    <v-col cols="12" align="center" class="pb-0 mb-0">
                        <img :width="!$vuetify.breakpoint.mobile ? '70px' : '70px'" contain :src="require('@/assets/contact-message-icon.svg')">
                    </v-col>
                    <v-col cols="12" align="center" class="pt-0 mt-0">
                        <span class="font-weight-bold text-h6">Στοιχεία Επικοινωνίας</span>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="12" align="center">
                        <div>
                            <span class="text-h6 custom-orange-text">Κινητό: </span>
                            <a href="tel:+306979575906" style="text-decoration: none; color: black;"><span class="text-subtitle-1">+306979575906</span></a>
                        </div>
                        <div>
                            <span class="text-h6 custom-orange-text ml-5">Email: </span>
                            <a href="mailto:ivalmas@backinthegame.gr" style="text-decoration: none; color: black;"><span class="text-subtitle-1">ivalmas@backinthegame.gr</span></a>
                        </div>
                    </v-col>
                </v-row>
                <v-row align="start">
                    <v-col cols="12" align="center" justify="center">
                        <v-divider
                            horizontal
                            color="orange"
                            class="custom-divider"
                            width="800px"
                        />
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="12" align="center">
                        <div>
                            <span class="text-h6 custom-orange-text">Κινητό: </span>
                            <a href="tel:+306979607540" style="text-decoration: none; color: black;"><span class="text-subtitle-1">+306979607540</span></a>
                        </div>
                        <div>
                            <span class="text-h6 custom-orange-text ml-5">Email: </span>
                            <a href="mailto:gvalmas@backinthegame.gr" style="text-decoration: none; color: black;"><span class="text-subtitle-1">gvalmas@backinthegame.gr</span></a>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        created() {
        },
        methods: {
        }
    }
</script>

<style scoped>
.custom-divider {
    border-width: 1px;
}
</style>
